import * as React from 'react';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { ScrollIconSVG } from '../../Atoms/Icons/Icons';

import './ScrollIcon.scss';

const ScrollIcon = () => {
  const intl = useIntl();
  const onClickScrollIcon = (e) => {
    e.preventDefault();
    const firstElement = document.querySelectorAll('.section_content')[0] || document.querySelector('.page_content');

    if (firstElement) {
      window.scrollTo({ top: firstElement.getBoundingClientRect().y - 10, behavior: 'smooth' });
    } else {
      alert('Nothing to scroll');
    }
  };

  return (
    <a href="#" className="scroll_icon" onClick={(e) => onClickScrollIcon(e)} tabIndex={0} aria-label={intl.formatMessage({ id: 'ariaLabel.access_content' })}>
      <span>Scroll</span>
      <ScrollIconSVG color="color_white" aria-hidden="true" />
    </a>
  );
};

export default ScrollIcon;
