import React from 'react';

import {
  BTNPlayTransparent,
  MasqueBannerPage,
  MasqueBannerPageMobile,
  MasqueBannerPageTablet,
  MasqueBannerPageReverse,
  MasqueBannerPageReverseMobile
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBanner.scss';

let classNames = require('classnames');

function PopIn() {
  document.querySelector('.popin_wrapper').style.display = 'block';
  document.getElementsByTagName('html')[0].style.overflow = 'hidden';
}

const PageBanner = ({ visuel, scroll, backgroundColor, children, reverse, hasVideo }) => {
  const { processUrl } = useMedias();

  return (
    <div className={classNames('page_banner', reverse ? 'page_banner_reverse' : '')}>

      <div className="visuel">
        {visuel && (
          <>
            <img
              className="only_desktop"
              src={processUrl(visuel?.image_style_uri?.gatsby_wide)}
              alt="talents"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
              }}
            />
            <img
              className="only_mobile_tablet"
              src={processUrl(visuel?.image_style_uri?.gatsby_large)}
              alt="talents"
              style={{ width: '100%', height: '500px', objectFit: 'cover' }}
            />
            {reverse && hasVideo ? <BTNPlayTransparent className="play" onClick={() => { PopIn() }} /> : null}
          </>
        )}

        {!reverse
          ? <><MasqueBannerPage className={classNames('shape_yellow_banner_bottom', 'only_desktop', backgroundColor || '')} /><MasqueBannerPageMobile className={classNames('shape_yellow_banner_bottom', 'only_mobile', backgroundColor || '')} /><MasqueBannerPageTablet className={classNames('shape_yellow_banner_bottom', 'only_tablet', backgroundColor || '')} /></>
          : <><MasqueBannerPageReverse className={classNames('shape_yellow_banner_bottom', 'only_desktop', backgroundColor || '')} /><MasqueBannerPageReverseMobile className={classNames('shape_yellow_banner_bottom ', 'only_tablet', backgroundColor || '')} /><MasqueBannerPageReverseMobile className={classNames('shape_yellow_banner_bottom', 'only_mobile', backgroundColor || '')} /></>
        }
      </div>

      <div className="visuel_overlay">
        {children}
        {(scroll && !reverse) && (
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
