import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ContactForm from '../../components/ContactForm/ContactForm';
import PageBanner from '../../components/PageBanner/PageBanner';
import Seo from '../../components/Seo/Seo';
import useMedias from '../../hooks/useMedias';
import Layout from '../../Layout';
import { TitleContact } from '../../Molecules/TitleContact/TitleContact';
import { TitlePage } from '../../Molecules/TitlePage/TitlePage';
import {
  getBreadCrumb
} from '../../Utils/Utils';

import './presse.scss';

const classNames = require('classnames');

const ContactTemplate = ({ data, pageContext }) => {
  const { getImage } = useMedias();
  const formData = data.forms.edges[0].node;
  const locale = formData?.langcode;
  const imagesArray = data.allImages.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, formData?.path?.alias);
  const [contactFormName, setContactFormName] = useState('');
  const contact_internal_id = pageContext.node_internal_id;
  // const ourContact = data.ourContact.edges[0].node;
  const intl = useIntl();
  const metaTags =  formData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });
  let pagesContact = [];

  for (let i = 1; i <= 12; i++) {
    pagesContact.push({
      id: i,
      label: intl.formatMessage({ id: 'contact.form.field.type.list.opt' + i })
    });
  }

  useEffect(() => {
    let _paq = window._paq = window._paq || [];
    _paq.push(['trackPageView']);
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
            title={formData?.field_metatag?.title ? formData?.field_metatag?.title : metaTitle}
            description={formData?.field_metatag?.description ? formData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_contact_form')}>
        <PageBanner
          visuel={getImage(
            imagesArray,
            formData.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={false}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: formData?.langcode,
              }}
            />
            <TitlePage color="color_white" title={formData.title} />
          </div>
        </PageBanner>
        <section
          className={classNames('section_content', 'section_contact_form')}
        >
          <div className="wrapper_page">
            <div className="frm-wrapper">
              <TitleContact title={intl.formatMessage({ id: 'contact.form.title' })}></TitleContact>
              <div className="col">
                <div className="contact_form_component_container">
                  <ContactForm formName="contact" langCode={locale} pagesContact={pagesContact} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContactTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    forms: allNodeWebform(
      filter: {
        langcode: { eq: $locale }
        path: { alias: { eq: $slug } }
      }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          field_metatag{
            title
            description
          }
          metatag {
            attributes {
              content
              name
            }
          }
          drupal_internal__nid
          title
          relationships {
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
          langcode
        }
      }
    }
    ourContact: allMenuLinkContentMenuLinkContent(
      filter: {
        langcode: { eq: $locale }
        menu_name: { eq: "footer" }
        drupal_internal__id: { eq: 45 }
      }
    ) {
      edges {
        node {
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

ContactTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ContactTemplate;
