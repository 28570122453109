import React from 'react';

import './checkbox.scss';
let classNames = require('classnames');

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { validator, field, change, optional } = this.props;
    const params = !optional ? 'accepted|' : '';

    return (
      <div className={classNames(['checkbox', field.customClass])}>
        <input
          id={field.id}
          name={field.name}
          type={field.type}
          checked={field.value}
          onChange={(e) => change(field.id, e.currentTarget.checked ? true : false)}
          aria-required={optional ? 'false' : 'true'}
          role={field.type}
          aria-checked={field.value ? 'true' : 'false'}
          tabIndex={0}
        />
        <label className="label" htmlFor={field.id} {...field.ariaLabelOfLabel && { 'aria-label': field.ariaLabelOfLabel }}>
          <span className="checkmark"></span>
          <span className="text" dangerouslySetInnerHTML={{ __html: field.label }} />
        </label>
        {validator && validator.message(field.id, field.value, params, { className: 'error' })}

      </div>
    );
  }
}

Checkbox.defaultProps = {
  label: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',
  name: 'checkbox-blabla'
};

export default Checkbox;
