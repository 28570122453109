import React, { useRef, useEffect, useState, useCallback } from 'react';

import SimpleReactValidator from 'simple-react-validator';
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { Button } from '../../Molecules/Button/Button';
import Checkbox from '../../Molecules/Checkbox/Checkbox';
import Input from '../../Molecules/Input/Input';
import Textarea from '../../Molecules/Textarea/Textarea';
import Dropdown from '../Dropdown/Dropdown';

import './ContactForm.scss';
import { LoaderIcon } from '../../Atoms/Icons/Icons';
const axios = require('axios').default;
let classNames = require('classnames');

const ContactForm = ({ formName, langCode, pagesContact }) => {
  const [, updateState] = React.useState();
  const intl = useIntl();

  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [organisationName, setOrganisationName] = useState('');
  const [fuctionName, setFuctionName] = useState('');
  const [phone, setPhone] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [communeName, setCommuneName] = useState('');
  const [typeDept, setTypeDept] = useState('');
  const [files, setFiles] = useState([]);
  const [filesRejected, setFileRejected] = useState("");
  const [uploadSizEexceeded, setUploadSizEexceeded] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const webFormSessionUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}session/token`;
  const webFormSubmitUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}${langCode}/webform_rest/submit?_format=json`;
  const webFormSubmitFileUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}webform_rest/${formName}/upload/piece_jointe?_format=json`;

  const fileValidator = (file) => {
    if (file.size > 10000000) {
      return {
        code: "file-too-large",
        message: intl.formatMessage({ id: 'contact.form.field.upload.file_too_large' }) || 'file is larger than 10MB',
      };
    }
    return null;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //maxSize: 20000000,
    validator: fileValidator,
    maxFiles: 3,
    onDrop: (acceptedFiles, fileRejections) => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      let old_new_files = [...files, ...newFiles]
      old_new_files = old_new_files.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name && t.size === value.size
        ))
      )
      console.log(fileRejections);
      setFileRejected(fileRejections);
      setFiles(old_new_files);
    },
    accept: {
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/rtf': ['.rtf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.ms-powerpoint': ['.pps'],
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
      'text/plain': ['.txt']
    }
  })
  const deleteUploadedFile = (file_index) => {
    const newFiles = [...files]
    newFiles.splice(file_index, 1)
    setFiles(newFiles)
  }

  const deleteUploadedRejectedFile = (file_index) => {
    const newFiles = [...filesRejected]
    newFiles.splice(file_index, 1)
    setFileRejected(newFiles)
  }

  useEffect(() => {
    console.log(files);
    let total_size = 0;
    files?.map(file => {
      total_size += file.size;
    });
    total_size = total_size != 0 ? total_size / 1024 / 1024 : total_size;
    if (total_size > 20) {
      setUploadSizEexceeded(true)
    } else {
      setUploadSizEexceeded(false)
    }
  }, [JSON.stringify(files)])

  useEffect(() => {
    setTypeDept(pagesContact[0].label);
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const locale = {
    'accepted': intl.messages['common.form.error.accepted'],
    'alpha': intl.messages['common.form.error.alpha'],
    'alpha_space': intl.messages['common.form.error.alpha_space'],
    'boolean': intl.messages['common.form.error.boolean'],
    'email': intl.messages['common.form.error.email'],
    'numeric': intl.messages['common.form.error.numeric'],
    'phone': intl.messages['common.form.error.phone'],
    'regex': intl.messages['common.form.error.regex'],
    'required': intl.messages['common.form.error.required'],
    'string': intl.messages['common.form.error.string'],
    'typeof': intl.messages['common.form.error.typeof'],
  };

  SimpleReactValidator.addLocale(langCode, locale);

  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, locale: langCode }));

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      if (uploadSizEexceeded || filesRejected.length > 0) {
        document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        return false;
      }
      setIsError(false);
      setSendingData(true);

      axios.get(webFormSessionUrl).then((result) => {
        if (result.status === 200) {
          let _paq = (window._paq = window._paq || []);
          _paq.push(['trackEvent', 'contact_page', 'submit button click', 'email', email]);
          const csrfToken = result.data;
          const formData = new FormData();
          // Use Promise.all to handle multiple file uploads asynchronously
          const uploadPromises = files.map((file, index) => {
            formData.append(`file-${index}`, file);
            return axios.post(webFormSubmitFileUrl, file, {
              headers: {
                'Content-Type': 'application/octet-stream',
                'Content-Disposition': 'file; filename="' + file.name + '"',
                'X-CSRF-Token': csrfToken,
              },
              body: formData,
            });
          });

          Promise.all(uploadPromises)
            .then((fileResponses) => {
              const pieceJointeValues = fileResponses.map((response) => response.data.fid[0].value);
              const requestData = {
                webform_id: formName,
                surname: lastname,
                name: firstname,
                message: message,
                email: email,
                organisation: organisationName,
                fonction: fuctionName,
                telephone: phone,
                departement: departmentName,
                commune: communeName,
                type: typeDept,
                piece_jointe: pieceJointeValues,
              };

              fetch(webFormSubmitUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': csrfToken,
                },
                body: JSON.stringify(requestData),
              })
                .then((response) => response.json())
                .then(
                  (data) => {
                    // On success, do whatever you want
                    setSendingData(false);
                    console.log('success', data);
                    setIsEmailSent(true);
                    window.scrollTo({
                      top: document.querySelector('.section_contact_form').offsetTop - 100,
                      behavior: 'smooth',
                    });
                  },
                  (error) => {
                    console.log('error', error);
                    setSendingData(false);
                    setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
                  },
                );
            })
            .catch((error) => {
              console.log('Error uploading files:', error);
              setSendingData(false);
              setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
            });
        }
      });
    } else {
      setSendingData(false);
      validator.current.showMessages();
      setIsError(true);
      setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
    }
  };

  const deptHandler = (val) => {
    setTypeDept(val.label);
  };
  return (
    <div className="contact_form_container">
      {isError &&
        <FormattedMessage id="common.form.required">{x => <span className="red">* {x}</span>}</FormattedMessage>
      }
      {
        isEmailSent &&
        <div className='email-sent'>
          <h3 aria-live="polite"><FormattedMessage id="common.form.email_sent" /></h3>
        </div>
      }
      {!isEmailSent && (
        <form>

          <Input
            validator={validator.current}
            field={{
              id: 'lastnamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.lastname.placeholder' }),
              name: 'lastnamexx',
              label: intl.formatMessage({ id: 'contact.form.field.lastname.label' }),
              value: lastname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setLastname('')}
            change={(value) => setLastname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'firstnamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.firstname.placeholder' }),
              name: 'firstnamexx',
              label: intl.formatMessage({ id: 'contact.form.field.firstname.label' }),
              value: firstname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setFirstname('')}
            change={(value) => setFirstname(value)}
          />
          <div className="input">
            <Dropdown
              customClass="dropdown-questions-footer"
              list={pagesContact}
              DropDownTitle={intl.formatMessage({ id: 'contact.form.field.type.label' })}
              onChange={deptHandler}
            />
          </div>

          <Input
            // validator={validator.current}
            field={{
              id: 'organisationNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.orgName.placeholder' }),
              name: 'organisationnamexx',
              label: intl.formatMessage({ id: 'contact.form.field.orgName.label' }),
              value: organisationName,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setOrganisationName('')}
            change={(value) => setOrganisationName(value)}
          />

          <Input
            validator={validator.current}
            field={{
              id: 'fuctionNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.orgFunction.placeholder' }),
              name: 'functionnamexx',
              label: intl.formatMessage({ id: 'contact.form.field.orgFunction.label' }),
              value: fuctionName,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setFuctionName('')}
            change={(value) => setFuctionName(value)}
          />

          <Input
            validator={validator.current}
            field={{
              id: 'emailxx',
              filed_type: 'input',
              type: 'email',
              placeholder: intl.formatMessage({ id: 'contact.form.field.email.placeholder' }),
              name: 'emailxx',
              label: intl.formatMessage({ id: 'contact.form.field.email.label' }),
              value: email,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setEmail('')}
            change={(value) => setEmail(value)}
          />

          <Input
            field={{
              id: 'phonexx',
              filed_type: 'input',
              type: 'input',
              placeholder: intl.formatMessage({ id: 'contact.form.field.phone.placeholder' }),
              name: 'phonexx',
              label: intl.formatMessage({ id: 'contact.form.field.phone.label' }),
              value: phone,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setPhone('')}
            change={(value) => setPhone(value)}
          />
          <Input
            //validator={validator.current}
            field={{
              id: 'DepartmentNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.dept.placeholder' }),
              name: 'DepartmentNamexx',
              label: intl.formatMessage({ id: 'contact.form.field.dept.label' }),
              value: departmentName,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setDepartmentName('')}
            change={(value) => setDepartmentName(value)}
          />
          {/*//communeName*/}
          <Input
            //validator={validator.current}
            field={{
              id: 'CommuneNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.commuine.placeholder' }),
              name: 'CommuneNamexx',
              label: intl.formatMessage({ id: 'contact.form.field.commuine.label' }),
              value: communeName,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setCommuneName('')}
            change={(value) => setCommuneName(value)}
          />
          <Textarea
            validator={validator.current}
            field={{
              id: 'messagexx',
              filed_type: 'textarea',
              type: 'textarea',
              placeholder: intl.formatMessage({ id: 'contact.form.field.message.placeholder' }),
              name: 'messagexx',
              label: intl.formatMessage({ id: 'contact.form.field.message.label' }),
              value: message,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => { setMessage('') }}
            change={(value) => { setMessage(value) }}
          />
          <div className="input">
            <label>{intl.formatMessage({ id: 'contact.form.field.upload.label' })}</label>
            <div className="filedrop_container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>{intl.formatMessage({ id: 'contact.form.field.upload.drop_your_file' })}</p> :
                    <>
                      <p className="line_1">{intl.formatMessage({ id: 'contact.form.field.upload.drop_or_select' })}</p>
                      <p className="line_2">{intl.formatMessage({ id: 'contact.form.field.upload.accepted_files' })}</p>
                    </>
                }
              </div>
            </div>
            {
              files?.length > 0 && (
                <div className="uploaded_files_listed">
                  <h5 className={uploadSizEexceeded ? 'error' : ''}>{intl.formatMessage({ id: 'contact.form.field.upload.title_files' })}</h5>
                  {
                    uploadSizEexceeded && <p className='error'>{intl.formatMessage({ id: 'contact.form.field.upload.upload_size_eexceeded' })}</p>
                  }
                  <ul>
                    {
                      files.map((file, index) => (
                        <li key={'index-' + index}>
                          {file.name} - {parseFloat(file.size / 1024 / 1024, 10).toFixed(2)} MB <span className="delete_icon" onClick={() => deleteUploadedFile(index)}>X</span>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )
            }
            {
              filesRejected?.length > 0 && (
                <div className="uploaded_files_listed rejected">
                  <h5>{intl.formatMessage({ id: 'contact.form.field.upload.title_files_rejected' })}</h5>
                  <ul>
                    {
                      filesRejected.map((rejectedfile, index) => (
                        <li key={'index-' + index} className="error">
                          {rejectedfile.file.name} - {parseFloat(rejectedfile.file.size / 1024 / 1024, 10).toFixed(2)} MB <span className="delete_icon" onClick={() => deleteUploadedRejectedFile(index)}>X</span><br />
                          <b>{rejectedfile.errors[0]?.message}</b>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )
            }
          </div>

          <Checkbox
            validator={validator.current}
            field={{
              id: 'accept_checkbox',
              filed_type: 'checkbox',
              type: 'checkbox',
              name: 'chkBox',//ReturnTranslation('form-accept', langCode),
              label: intl.formatMessage({ id: 'contact.compulsory' }), //ReturnTranslation('form-accept', langCode) + '*',
              value: acceptCondition
            }}
            change={(id, value) => setAcceptCondition(value)}
          />
          {/* <!-- H o n e y p o t --> */}
          <label className="ohnohoney" htmlFor="lastname"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="lastname" name="lastname" placeholder="Your lastname here" />
          <label className="ohnohoney" htmlFor="firstname"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="firstname" name="firstname" placeholder="Your firstname here" />
          <label className="ohnohoney" htmlFor="organisationname"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="organisationname" name="organisationname" placeholder="Your organisationname here" />
          <label className="ohnohoney" htmlFor="functionname"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="functionname" name="functionname" placeholder="Your functionname here" />
          <label className="ohnohoney" htmlFor="phone"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="phone" name="phone" placeholder="Your phone here" />
          <label className="ohnohoney" htmlFor="DepartmentName"></label>
          <input className="ohnohoney" autoComplete="off" type="text" id="DepartmentName" name="DepartmentName" placeholder="Your DepartmentName here" />
          <label className="ohnohoney" htmlFor="CommuneName"></label>
          <input className="ohnohoney" autoComplete="off" type="email" id="CommuneName" name="CommuneName" placeholder="Your CommuneName here" />
          <label className="ohnohoney" htmlFor="message"></label>
          <input className="ohnohoney" autoComplete="off" type="email" id="message" name="message" placeholder="Your message here" />

          <div className="btn_container">
            <Button
              id="submit"
              primary={true}
              label={intl.formatMessage({ id: 'contact.cta.submit' })}
              onClick={(e) => { e.preventDefault(); handleSubmitForm(e) }}
              disabled={sendingData}
            />
            {
              sendingData &&
              <LoaderIcon className="spin_loader" />
            }
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
